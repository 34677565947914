import { VscGraph } from "react-icons/vsc";
import { CgProfile } from "react-icons/cg";
import { FaClipboardList } from "react-icons/fa";
import { PiUsersThreeFill, PiFolderUserFill } from "react-icons/pi";
import { BsCardChecklist, BsFillPinMapFill } from "react-icons/bs";
import { MdMapsHomeWork, MdOutlineDryCleaning, MdInventory } from "react-icons/md";

export const menu = [
    {
      id: 1,
      title: "MAIN",
      listItems: [
        {
          id: 1,
          title: "Dashboard",
          url: "/dashboard",
          icon: <VscGraph />,
        },
        {
          id: 2,
          title: "Profile",
          url: "/profile",
          icon: <CgProfile />,
        },
      ],
    },
    {
      id: 2,
      title: "LISTS",
      listItems: [
        {
          id: 1,
          title: "My Properties",
          url: "/properties",
          icon: <MdMapsHomeWork />,
        },
        {
          id: 2,
          title: "Users",
          url: "/users",
          icon: <PiUsersThreeFill />,
        },
        {
          id: 3,
          title: "Jobs",
          url: "/jobs",
          icon: <MdOutlineDryCleaning />,
        },
        {
          id: 4,
          title: "Inventory",
          url: "/inventory",
          icon: <MdInventory />,
        },
      ],
    },
    {
      id: 3,
      title: "REPORTS",
      listItems: [
        {
          id: 1,
          title: "Property Reports",
          url: "/property-reports",
          icon: <BsFillPinMapFill />,
        },
        {
          id: 2,
          title: "Job Reports",
          url: "/job-reports",
          icon: <FaClipboardList />,
        },
        {
          id: 3,
          title: "Inventory Reports",
          url: "/inventory-reports",
          icon: <BsCardChecklist />,
        },
      ],
    },
    // {
    //   id: 4,
    //   title: "SETTINGS",
    //   listItems: [
    //     {
    //       id: 1,
    //       title: "System Settings",
    //       url: "/",
    //       icon: <SettingsOutlinedIcon/>,
    //     },
    //     {
    //       id: 1,
    //       title: "Role Settings",
    //       url: "/",
    //       icon: "setting.svg",
    //     },
    //     {
    //       id: 2,
    //       title: "Property Settings",
    //       url: "/",
    //       icon: "backup.svg",
    //     },
    //     {
    //       id: 2,
    //       title: "Inventory Settings",
    //       url: "/",
    //       icon: "backup.svg",
    //     },
    //     {
    //       id: 2,
    //       title: "Job Settings",
    //       url: "/",
    //       icon: "backup.svg",
    //     },
    //   ],
    // },
  ];