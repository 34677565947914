import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import React from "react";
import { FiSettings } from "react-icons/fi";
import { useStateContext } from "../contexts/ContextProvider";
import { Navbar, Sidebar } from "../components";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const GuestLayout = () => {
  const { activeMenu, user, token, setUser, setToken } = useStateContext();
  const location = useLocation();
  const { pathname } = location;

  if (token && pathname !== "/set-password/" && pathname !== "/reset-password/") {
    return <Navigate to="/dashboard"/>
  }
  
  return (
    <div>
        <Outlet />
    </div>
  );
};

export default GuestLayout;
