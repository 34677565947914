import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { registerLicense } from '@syncfusion/ej2-base';

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9edXRSR2hdU0x+WkA=');

ReactDOM.render(
  <ContextProvider>
    <SnackbarProvider maxSnack={1} dense="true" autoHideDuration={3000}>
    <RouterProvider router={router} />
    </SnackbarProvider>
  </ContextProvider>,
  document.getElementById("root")
);