import { Box, IconButton, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { CgClose } from "react-icons/cg";

function CustomSnackbar({ open, onClose, message, severity }) {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
      >
        <CgClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        message="Note archived"
        action={action}
        variant="success"
      />
    </div>
  );
}

export default CustomSnackbar;
