import React from "react";
import ImageUploading from "react-images-uploading";
import "../styles/imageUpload.css";
import { BiImageAdd } from "react-icons/bi";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";

const ImageUpload = ({ updateImageList }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = (imageList, addUpdateIndex) => {
    const lastAdded = imageList[imageList.length - 1];
    if (lastAdded && lastAdded.file.size > 2097152) {
      enqueueSnackbar("File size should not exceed 2MB", { variant: "error" });
    } else {
      setImages(imageList);
      updateImageList(imageList);
    }
  };

  const imagesPerRowDesktop = 6; // Number of images per row on desktop
  const imagesPerRowTablet = 4; // Number of images per row on tablets
  const imagesPerRowMobile = 2; // Number of images per row on mobile devices

  const renderImageRows = (imageList, onImageRemove, imagesPerRow) => {
    const rows = [];

    for (let i = 0; i < imageList.length; i += imagesPerRow) {
      const rowImages = imageList.slice(i, i + imagesPerRow);

      rows.push(
        <div className="flex gap-5" key={i}>
          {rowImages.map((image, index) => (
            <div key={index} className="image-item">
              <img src={image.data_url} alt="" width="100" />
              <button
                onClick={() => onImageRemove(index)}
                className="button-delete"
              >
                x
              </button>
            </div>
          ))}
        </div>
      );
    }

    return rows;
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
        acceptType={["jpg", "jpeg", "png", "gif", "svg", "webp"]}
      >
        {({ imageList, onImageUpload, onImageRemove, errors }) => (
          <div className="upload__image-wrapper">
            <Button
              size="small"
              variant="outlined"
              color="success"
              onClick={onImageUpload}
              startIcon={<BiImageAdd />}
            >
              Upload
            </Button>
            {window.innerWidth > 768
              ? renderImageRows(imageList, onImageRemove, imagesPerRowDesktop)
              : window.innerWidth > 480
              ? renderImageRows(imageList, onImageRemove, imagesPerRowTablet)
              : renderImageRows(imageList, onImageRemove, imagesPerRowMobile)}
          </div>
        )}
      </ImageUploading>
    </div>
  );
};

export default ImageUpload;
