import React, { useEffect, useState } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { GoEye } from "react-icons/go";
import { BiSolidEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { AiOutlineUserAdd } from "react-icons/ai";

import { usersGrid } from "../../data/usersData";
import { Header } from "../../components";
import { useUserListStateContext } from "../../contexts/UserListContextProvider";
import Button from "@mui/material/Button";
import axiosClient from "../../axios-client";
import DeleteUser from "../Modals/DeleteUser";
import Toast from "../../components/Toast";
import { enqueueSnackbar } from "notistack";
import ViewUser from "../Modals/ViewUser";
import { useNavigate } from "react-router-dom";
import EditUser from "../Modals/EditUser";
import AddUser from "../Modals/AddUser";

const Users = () => {
  const {
    userData,
    setUserData,
    openAddModal,
    setOpenAddModal,
    openViewModal,
    setOpenViewModal,
    openDeleteModal,
    setOpenDeleteModal,
    openEditModal,
    setOpenEditModal,
    selectedUser,
    setSelectedUser,
  } = useUserListStateContext();

  const toolbarOptions = ["Search"];
  const navigate = useNavigate();

  const editing = { allowDeleting: true, allowEditing: true };

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const userListButtons = (props) => (
    <div className="flex items-center justify-center gap-2">
      <ActionButton
        title="View"
        customFunc={() => handleView(props)}
        icon={<GoEye />}
        color="#4d4c5c"
      />
      <ActionButton
        title="Edit"
        customFunc={() => handleEdit(props)}
        icon={<BiSolidEditAlt />}
        color="#4169e1"
      />
      <ActionButton
        title="Delete"
        customFunc={() => handleDelete(props)}
        icon={<RiDeleteBin6Line />}
        color="#e3242b"
      />
    </div>
  );

  const handleView = (data) => {
    setSelectedUser(data);
    setOpenViewModal(true);
  };

  const handleEdit = (data) => {
    setSelectedUser(data);
    setOpenEditModal(true);
  };

  const handleDelete = (data) => {
    setSelectedUser(data);
    setOpenDeleteModal(true);
  };

  const handleAdd = () => {
    setOpenAddModal(true);
  };
  
  useEffect(() => {
    axiosClient
      .get("/users")
      .then(({ data }) => {
        setUserData(data.users);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Lists" title="Users" />
      <div className="flex">
        <TooltipComponent content="Add User" position="BottomCenter">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            startIcon={<AiOutlineUserAdd />}
            color="success"
            onClick={handleAdd}
          >
            Add User
          </Button>
        </TooltipComponent>
      </div>
      <GridComponent
        dataSource={userData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        statelessTemplates={["directiveTemplates"]}
        allowSelection={false}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {usersGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
          <ColumnDirective
            key="actions"
            headerText="Actions"
            width="100"
            textAlign="Center"
            template={userListButtons}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Sort]} />
      </GridComponent>

      {openDeleteModal && (
        <DeleteUser
          setOpenDeleteModal={setOpenDeleteModal}
          user={selectedUser}
          setUserData={setUserData}
        />
      )}
      {openViewModal && (
        <ViewUser setOpenViewModal={setOpenViewModal} user={selectedUser} />
      )}
      {openEditModal && (
        <EditUser
          setOpenEditModal={setOpenEditModal}
          user={selectedUser}
          setUserData={setUserData}
        />
      )}
      {openAddModal && <AddUser setOpenAddModal={setOpenAddModal} 
          setUserData={setUserData} />}
    </div>
  );
};
export default Users;
