import React, { createContext, useContext, useState } from "react";

const PropertyReportStateContext = createContext();

export const PropertyReportsContextProvider = ({ children }) => {
  const [openReportModal, setOpenReportModal] = useState(false);
  const [propertyData, setPropertyData] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  return (
    <PropertyReportStateContext.Provider
      value={{
        openReportModal,
        setOpenReportModal,
        selectedProperty,
        setSelectedProperty,
        propertyData,
        setPropertyData
      }}
    >
      {children}
    </PropertyReportStateContext.Provider>
  );
};

export const usePropertyReportStateContext = () => useContext(PropertyReportStateContext);
