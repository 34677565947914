import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import axiosClient from "../../axios-client";
import { FaFilter } from "react-icons/fa";
import { enqueueSnackbar } from "notistack";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
} from "@syncfusion/ej2-react-grids";
import { DetailRow, Inject } from "@syncfusion/ej2-react-grids";

const ViewPropertyReport = ({ setOpenReportModal, property }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState("");
  const [totalReservations, setTotalReservations] = useState("-");
  const [daysOccupied, setDaysOccupied] = useState("-");
  const [average, setAverage] = useState("-");
  const [reservationData, setReservationData] = useState(null);

  // input field error state declarations
  const [filterError, setFilterError] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleDateRangeChange = (event) => {
    setSelectedDateRange(event.target.value);
    setStartDate(null);
    setEndDate(null);

    setFilterError({
      isError: false,
      errorMessage: "",
    });

    switch (event.target.value) {
      case "thisMonth":
        handleDateRangeButtonClick(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
        );
        break;
      case "lastMonth":
        handleDateRangeButtonClick(
          new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth(), 0)
        );
        break;
      case "nextMonth":
        handleDateRangeButtonClick(
          new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1),
          new Date(new Date().getFullYear(), new Date().getMonth() + 2, 0)
        );
      // Add cases for other date ranges as needed
      default:
        break;
    }
  };

  const filterData = () => {
    setFilterError({
      isError: false,
      errorMessage: "",
    });

    if (!selectedDateRange || !startDate || !endDate) {
      setFilterError({
        isError: true,
        errorMessage: "Filter should not be empty",
      });

      return;
    }

    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedStartDate = startDate.toLocaleDateString("en-AU", options);
    const formattedEndDate = endDate.toLocaleDateString("en-AU", options);

    // Create query parameters
    const queryParams = {
      from: formattedStartDate.split("/").reverse().join("-"),
      to: formattedEndDate.split("/").reverse().join("-"),
    };

    axiosClient
      .get(`/reports/properties/${property.id}/metrics`, {
        params: queryParams,
      })
      .then(({ data }) => {
        setTotalReservations(data.metrics.reservationCount);
        setDaysOccupied(data.metrics.occupiedDaysCount);
        setAverage(data.metrics.avgDaysPerReservation);
      })
      .catch((error) => {
        console.error(error);
      });

    axiosClient
      .get(`/reports/properties/${property.id}`, {
        params: queryParams,
      })
      .then(({ data }) => {
        setReservationData(data.reservations);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDateRangeButtonClick = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleModalClose = () => {
    setOpenReportModal(false);
  };

  const handleCustomDateRangeChange = (props) => {
    setStartDate(props.startDate);
    setEndDate(props.endDate);
  };

  const childGridOptions = {
    columns: [
      {
        field: "cleaning_job.id",
        headerText: "Cleaning Job ID",
        textAlign: "Left",
        width: 80,
      },
      { field: "cleaning_job.cleaner", headerText: "Cleaner", textAlign: "Left", width: 180 },
      { field: "cleaning_job.status", headerText: "Status", textAlign: "Left", width: 100 },
      { field: "cleaning_job.schedule", headerText: "Schedule", textAlign: "Left", width: 150 },
    ],
    dataSource: reservationData,
    queryString: "id", // Assuming 'id' is the unique identifier for each item in metrics
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
      <div className="bg-white w-full max-w-6xl max-h-6xl rounded-lg shadow-lg overflow-y-auto">
        {/* Modal Header */}
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              View Property Report - {property.name}
            </h2>
            <button
              onClick={() => setOpenReportModal(false)}
              className={`text-gray-500 "hover:text-black"}`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Modal Body */}
        <div className="flex p-4">
          <Grid container spacing={3}>
            {/* Row 1 */}
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                select // tell TextField to render select
                label="Filter"
                size="small"
                value={selectedDateRange}
                onChange={handleDateRangeChange}
                fullWidth
                error={filterError.isError}
                helperText={filterError.isError ? filterError.errorMessage : ""}
              >
                <MenuItem value="thisMonth">This Month</MenuItem>
                <MenuItem value="lastMonth">Last Month</MenuItem>
                <MenuItem value="nextMonth">Next Month</MenuItem>
                {/* Add menu items for other date ranges as needed */}
                <MenuItem value="custom">Custom</MenuItem>
              </TextField>
            </Grid>
            {selectedDateRange === "custom" && (
              <>
                <Grid item xs={6} sm={3} md={6}>
                  <DateRangePickerComponent
                    placeholder="Select a date range"
                    change={handleCustomDateRangeChange}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={6} sm={3} md={2}>
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={filterData}
                disabled={false}
                startIcon={<FaFilter />}
              >
                Filter
              </Button>
            </Grid>
          </Grid>
        </div>

        <div className="p-4 flex">
          <div className="grid grid-cols-2 gap-4">
            <div key="key" className="mb-4">
              <div className="text-sm font-medium text-gray-500 uppercase mb-1">
                Total Reservations
              </div>
              <div className="text-lg font-semibold text-indigo-500">
                {totalReservations}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div key="key" className="mb-4">
              <div className="text-sm font-medium text-gray-500 uppercase mb-1">
                Total Days Occupied
              </div>
              <div className="text-lg font-semibold text-indigo-500">
                {daysOccupied}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div key="key" className="mb-4">
              <div className="text-sm font-medium text-gray-500 uppercase mb-1">
                Average Days per Reservation
              </div>
              <div className="text-lg font-semibold text-indigo-500">
                {average}
              </div>
            </div>
          </div>
        </div>

        <div className="p-4 flex">
          <div className="grid grid-cols-1 gap-4">
            <GridComponent
              dataSource={reservationData}
              childGrid={childGridOptions}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText="ID"
                  width="80"
                  textAlign="Right"
                />
                <ColumnDirective field="name" headerText="Name" width="150" />
                <ColumnDirective field="phone" headerText="Phone" width="100" />
                <ColumnDirective
                  field="duration"
                  headerText="Duration"
                  width="150"
                />
                <ColumnDirective
                  field="reservation_code"
                  headerText="Reservation Code"
                  width="180"
                />
              </ColumnsDirective>
              <Inject services={[DetailRow]} />
            </GridComponent>
          </div>
        </div>

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2 -pt-2 border-t-2">
          <div className="pt-3">
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="info"
              onClick={handleModalClose}
            >
              Back
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewPropertyReport;
