import { BiCheck, BiX } from "react-icons/bi";
import { FaTimes } from "react-icons/lia";

const gridPropertyName = (props) => (
  <div className="flex items-center gap-2">
    <img className="box-full w-20 h-20" src={props.image_url} alt="property" />
    <div className="flex flex-col">
      <p className="font-medium font-lg">{props.name}</p>
    </div>
  </div>
);

const gridPropertyNameWithCleaner = (props) => (
  <div className="flex items-center gap-2">
    <img className="box-full w-10 h-10" src={props.image_url} alt="property" />
    <div className="flex flex-col">
      <p className="font-semibold font-sm">{props.name}</p>
      <p className="font-xs text-pink-500">Cleaner: {props.cleaner_name}</p>
    </div>
  </div>
);

const gridPropertyIsListed = (props) => (
  <div className="text-lg items-center justify-center">
    {props.is_listed ? (
      <div>
        <BiCheck />
      </div>
    ) : (
      <div>
        <BiX />
      </div>
    )}
  </div>
);

export const propertiesGrid = [
  {
    headerText: "Name",
    width: "220",
    textAlign: "Left",
    field: "name",
    template: gridPropertyNameWithCleaner,
  },
  {
    field: "address",
    headerText: "Address",
    width: "170",
    textAlign: "Left",
  },
  {
    field: "is_listed",
    headerText: "Is Listed?",
    width: "80",
    textAlign: "Left",
    template: gridPropertyIsListed,
  },
  {
    field: "listings",
    headerText: "No. of Listings",
    width: "80",
    textAlign: "Left",
  },
];

export const propertyReportsGrid = [
  {
    headerText: "Name",
    width: "220",
    textAlign: "Center",
    field: "name",
    template: gridPropertyName,
  },
  {
    field: "address",
    headerText: "Address",
    width: "170",
    textAlign: "Center",
  }
];
