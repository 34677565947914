import React, { useEffect } from "react";
import {
  GridComponent,
  Inject,
  ColumnsDirective,
  ColumnDirective,
  Search,
  Page,
  Toolbar,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { BiSolidEditAlt } from "react-icons/bi";
import { GoSync, GoEye } from "react-icons/go";
import { propertyReportsGrid } from "../../data/propertiesData";
import { Header } from "../../components";
import Button from "@mui/material/Button";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import EditUser from "../Modals/EditUser";
import { usePropertyReportStateContext } from "../../contexts/PropertyReportsContextProvider.js";
import { TbReportSearch } from "react-icons/tb";
import SyncProperties from "../Modals/SyncProperties.jsx";
import { useStateContext } from "../../contexts/ContextProvider";
import PuffLoader from "react-spinners/PuffLoader";
import { TypeAnimation } from "react-type-animation";
import ManageProperty from "../Modals/ManageProperty";
import { CircularProgress } from "@mui/material";
import ViewPropertyReport from "../Modals/ViewPropertyReport.jsx";

const PropertyReports = () => {
  const {
    propertyData,
    setPropertyData,
    openReportModal,
    setOpenReportModal,
    selectedProperty,
    setSelectedProperty,
  } = usePropertyReportStateContext();

  const toolbarOptions = ["Search"];

  const editing = { allowDeleting: true, allowEditing: true };

  const ActionButton = ({ title, customFunc, icon, color }) => (
    <TooltipComponent content={title} position="BottomCenter">
      <button
        type="button"
        onClick={customFunc}
        style={{ color }}
        className="relative text-lg rounder-full p-3 hover:bg-light-gray"
      >
        <span className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2" />
        {icon}
      </button>
    </TooltipComponent>
  );

  const propertyListButtons = (props) => (
    <div className="flex items-center justify-center gap-2">
      <ActionButton
        title="Generate Report"
        customFunc={() => generateReport(props)}
        icon={<TbReportSearch />}
        color="#4d4c5c"
      />
    </div>
  );

  const generateReport = (data) => {
    setSelectedProperty(data);
    setOpenReportModal(true);
  };

  useEffect(() => {
    axiosClient
      .get("/properties/all")
      .then(({ data }) => {
        setPropertyData(data.properties);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  }, []);

  return (
    <div className="m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl">
      <Header category="Reports" title="Property Reports" />

      {/* if data is not syncing */}

      <GridComponent
        dataSource={propertyData}
        width="auto"
        allowPaging
        allowSorting
        pageSettings={{ pageCount: 5, pageSize: 10 }}
        editSettings={editing}
        toolbar={toolbarOptions}
        statelessTemplates={["directiveTemplates"]}
        allowSelection={false}
      >
        <ColumnsDirective>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {propertyReportsGrid.map((item, index) => (
            <ColumnDirective key={index} {...item} />
          ))}
          <ColumnDirective
            key="actions"
            headerText="Actions"
            width="100"
            textAlign="Center"
            template={propertyListButtons}
          />
        </ColumnsDirective>
        <Inject services={[Search, Page, Toolbar, Sort]} />
      </GridComponent>

      {openReportModal && (
        <ViewPropertyReport
          setOpenReportModal={setOpenReportModal}
          property={selectedProperty}
        />
      )}
    </div>
  );
};
export default PropertyReports;
