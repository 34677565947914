import {
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { IoArrowRedoSharp } from "react-icons/io5";
import Scheduler from "../../components/Scheduler";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import { useStateContext } from "../../contexts/ContextProvider";

function ListingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ListingTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: "#4ac299",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#FFF",
    },
  },
});

const ManageProperty = ({
  setOpenEditModal,
  property,
  setPropertyData,
  type,
}) => {
  const { isAdmin } = useStateContext();

  const [value, setValue] = useState(0);
  const [cleanerSelectItems, setCleanerSelectItems] = useState([]);
  const [cleaner, setCleaner] = useState("");
  const [didSubmit, setDidSubmit] = useState(false);
  const [resourceData, setResourceData] = useState(null);

  // populate cleaner data
  useEffect(() => {
    axiosClient.get("/users?role=cleaner").then(({ data }) => {
      let cleanerData = Object.entries(data.users).map((item) => {
        return {
          label: item[1].name,
          value: item[1].id,
        };
      });

      setCleanerSelectItems(cleanerData);
      setCleaner(property.cleaner_id ?? "");
    });
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCleanerChange = (event) => {
    setCleaner(event.target.value);
  };

  const handleModalClose = () => {
    setResourceData(null);
    setOpenEditModal(false);
  };

  const updateCleaner = () => {
    setDidSubmit(true);
    let payload = {
      cleaner_id: cleaner,
    };

    axiosClient
      .put(`/properties/${property.id}/update-cleaner`, payload)
      .then(() => {
        setDidSubmit(false);
        setOpenEditModal(false);
        getProperties();
        setCleaner("");
        setCleanerSelectItems([]);
        setResourceData(null);
        enqueueSnackbar("Property has been updated", {
          variant: "success",
        });
      })
      .catch((err) => {
        const response = err.response;
        setDidSubmit(false);
        setOpenEditModal(false);
        setCleaner("");
        setCleanerSelectItems([]);
        setResourceData(null);
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const getProperties = () => {
    axiosClient
      .get(`/properties/${type}`)
      .then(({ data }) => {
        setPropertyData(data.properties);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const tabContentStyles = {
    transition: "opacity 0.3s ease-in-out",
    opacity: value === 0 ? 1 : 0, // Show "Property Details" tab content if it's selected
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
        <div className="relative bg-white w-full max-w-7xl rounded-lg shadow-lg">
          {/* Modal Header */}
          {/* Header */}
          <div className="bg-gray-100 py-2 px-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
                {property.name}
              </h2>
              <button
                InputProps={{
                  readOnly: true,
                }}
                onClick={handleModalClose}
                className={`text-gray-500`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Modal Body */}
          <div className="max-h-[60vh] overflow-y-auto p-6">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="Property Tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: green,
                    },
                  }}
                >
                  <Tab label="Property Details" />
                  {property.listings.map((listing, index) => (
                    <Tab key={index} label={`Listing ${index + 1}`} />
                  ))}
                </Tabs>
              </Box>
              {value === 0 && (
                <TabPanel
                  key={0}
                  value={value}
                  index={0}
                  style={tabContentStyles}
                >
                  <div className="flex px-5 py-5">
                    <div className="flex-1 ">
                      <img
                        src={property.image_url}
                        alt="Property Image"
                        className="w-full h-auto rounded-lg"
                      />
                    </div>

                    <div className="flex-1 p-4">
                      <div>
                        <h2 className="text-2xl font-semibold mb-4">
                          {property.name}
                        </h2>
                        <p className="text-gray-700 -mt-4 text-sm">
                          {property.address}
                        </p>
                        <p
                          className="text-gray-600 mt-4"
                          style={{ fontSize: "18px" }}
                        >
                          {property.description}
                        </p>
                        <div className="py-4">
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={8}>
                              <TextField
                                select // tell TextField to render select
                                label="Cleaner"
                                size="small"
                                value={cleaner}
                                fullWidth
                                disabled={!isAdmin}
                                // onBlur={validateRole}
                                onChange={handleCleanerChange}
                              >
                                {cleanerSelectItems.map((item) => (
                                  <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </Grid>
                        </div>
                        {type === "airbnb" && (
                          <a
                            href={property.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-gray-600 flex items-center hover:text-green-600 text-sm"
                          >
                            Visit property at Hospitable
                            <IoArrowRedoSharp className="ml-2" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </TabPanel>
              )}
              {property.listings.map((listing, index) => (
                <TabPanel
                  key={index}
                  value={value}
                  index={index + 1}
                  style={tabContentStyles}
                >
                  {/* Property details for each listing */}
                  <div className="flex px-5 py-5">
                    <div className="flex-1">
                      <img
                        src={listing.image_url}
                        alt="Property Image"
                        className="w-full h-auto rounded-lg"
                      />
                    </div>

                    <div className="flex-1 p-4">
                      <div>
                        <h2 className="text-2xl font-semibold mb-4">
                          {listing.name}
                        </h2>
                        <p className="text-gray-700 -mt-4 text-sm">
                          {listing.room_type}
                        </p>
                        <p
                          className="text-gray-600 mt-8"
                          style={{ fontSize: "16px" }}
                        >
                          Capacity: {listing.capacity.max} guests
                        </p>
                        <p
                          className="text-gray-600 mt-2"
                          style={{ fontSize: "16px" }}
                        >
                          Bedrooms: {listing.capacity.bedrooms}
                        </p>
                        <p
                          className="text-gray-600 mt-2"
                          style={{ fontSize: "16px" }}
                        >
                          Beds: {listing.capacity.beds}
                        </p>
                        <p
                          className="text-gray-600 mt-2"
                          style={{ fontSize: "16px" }}
                        >
                          Bathrooms: {listing.capacity.bathrooms}
                        </p>
                        <p
                          className="text-gray-600 mt-8"
                          style={{ fontSize: "18px" }}
                        >
                          Check-in: {listing.checkin} | Check-out:{" "}
                          {listing.checkout}
                        </p>
                        (
                        <a
                          href={listing.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-600 mt-6 flex items-center hover:text-green-600 text-sm"
                        >
                          View listing at Airbnb{" "}
                          <IoArrowRedoSharp className="ml-2" />
                        </a>
                        )
                      </div>
                    </div>
                  </div>
                  <Box p={3}>
                    {" "}
                    <p className="text-gray-600 -mt-6">Amenities:</p>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "8px",
                      }}
                    >
                      {listing.amenities.map((amenity, idx) => (
                        <Chip
                          key={idx}
                          label={amenity}
                          style={{ margin: "4px" }}
                          size="small"
                          variant="outlined"
                          className="text-sm"
                        />
                      ))}
                    </div>
                  </Box>
                  <Box p={3}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        marginTop: "8px",
                      }}
                    >
                      <Scheduler
                        listing={listing}
                        resourceData={resourceData}
                        setResourceData={setResourceData}
                      />
                    </div>
                  </Box>
                </TabPanel>
              ))}
            </Box>
          </div>
          {/* Footer */}
          <div className="py-3 px-4 flex justify-end gap-2 -pt-2">
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="info"
              onClick={handleModalClose}
              disabled={didSubmit}
            >
              Back
            </Button>

            {value === 0 && isAdmin && (
              <>
                <Button
                  className="bottom-3"
                  size="small"
                  variant="outlined"
                  color="success"
                  onClick={updateCleaner}
                  disabled={didSubmit}
                  startIcon={
                    didSubmit ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <></>
                    )
                  }
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export default ManageProperty;
