import {
  Button,
  Typography,
  Tabs,
  Tab,
  Box,
  Chip,
  CircularProgress,
  Grid,
  TextField,
  MenuItem,
} from "@mui/material";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { green } from "@mui/material/colors";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { enqueueSnackbar } from "notistack";
import { useStateContext } from "../../contexts/ContextProvider";

function ListingTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

ListingTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const theme = createTheme({
  palette: {
    primary: green,
    secondary: {
      main: "#4ac299",
      light: "#F5EBFF",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#FFF",
    },
  },
});

const AddProperty = ({ setOpenAddModal, setPropertyData, type }) => {
  const { isAdmin } = useStateContext();

  const [value, setValue] = useState(0);
  const [didSubmit, setDidSubmit] = useState(false);
  const [image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({ property: {}, listings: [] });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePropertyFieldChange = (event, name) => {
    setValues((prevValues) => ({
      ...prevValues,
      property: {
        ...prevValues.property,
        [name]: event.target.value,
      },
    }));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setValues((prevValues) => ({
      ...prevValues,
      property: {
        ...prevValues.property,
        image: file,
      },
    }));

    setImage(URL.createObjectURL(event.target.files[0]));
  };

  const handleModalClose = () => {
    setOpenAddModal(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setDidSubmit(true);
    const formData = new FormData();

    // Append property fields to formData
    for (const key in values.property) {
      formData.append(key, values.property[key]);
    }

    // Now you can send formData to your API
    axiosClient
      .post("/properties/add", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(() => {
        getProperties();
        setDidSubmit(false);
        setOpenAddModal(false);
        enqueueSnackbar("Property added successfully", {
          variant: "success",
        });
      })
      .catch((err) => {
        setDidSubmit(false);
        const response = err.response;
        if (response && response.data.errors) {
          if (response.data.errors.image) {
            enqueueSnackbar(response.data.errors.image[0], {
              variant: "error",
            });
          } else {
            setErrors(response.data.errors);
          }
        } else {
          enqueueSnackbar(response.data.message, {
            variant: "error",
          });
        }
      });
  };

  const getProperties = () => {
    axiosClient
      .get(`/properties/${type}`)
      .then(({ data }) => {
        setPropertyData(data.properties);
      })
      .catch((err) => {
        const response = err.response;
        enqueueSnackbar(response.data.message, {
          variant: "error",
        });
      });
  };

  const tabContentStyles = {
    transition: "opacity 0.3s ease-in-out",
    opacity: value === 0 ? 1 : 0, // Show "Property Details" tab content if it's selected
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center min-h-screen z-50">
        <div className="relative bg-white w-full max-w-7xl rounded-lg shadow-lg">
          {/* Modal Header */}
          {/* Header */}
          <div className="bg-gray-100 py-2 px-4">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
                Add New Property
              </h2>
              <button
                InputProps={{
                  readOnly: true,
                }}
                onClick={handleModalClose}
                className={`text-gray-500`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>

          {/* Modal Body */}
          <div className="max-h-[60vh] overflow-y-auto p-6">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="Property Tabs"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: green,
                    },
                  }}
                >
                  <Tab label="Property Details" />
                </Tabs>
              </Box>
              {value === 0 && (
                <TabPanel
                  key={0}
                  value={value}
                  index={0}
                  style={tabContentStyles}
                >
                  <div className="flex px-5">
                    <div className="flex-1 p-2 space-y-2">
                      {values.property.image ? (
                        <img
                          src={image}
                          alt="Property Image"
                          className="w-full h-72 rounded-lg"
                        />
                      ) : (
                        <div className="w-full h-72 rounded-lg border-dashed border-2 border-gray-500 flex items-center justify-center">
                          <span className="text-2xl">+</span>
                        </div>
                      )}
                      <input type="file" onChange={handleImageChange} />
                    </div>
                    <div className="flex-1 p-2">
                      <div className="space-y-6">
                        <TextField
                          label="Property Name"
                          value={values.property.name}
                          onChange={(e) => handlePropertyFieldChange(e, "name")}
                          fullWidth
                          error={errors.name}
                          helperText={errors.name}
                        />
                        <TextField
                          label="Address"
                          value={values.property.address}
                          onChange={(e) =>
                            handlePropertyFieldChange(e, "address")
                          }
                          fullWidth
                          error={errors.address}
                          helperText={errors.address}
                        />
                        <TextField
                          label="Description"
                          value={values.property.description}
                          multiline
                          rows={4}
                          onChange={(e) =>
                            handlePropertyFieldChange(e, "description")
                          }
                          fullWidth
                          error={errors.description}
                          helperText={errors.description}
                        />{" "}
                      </div>
                    </div>
                  </div>
                  {/* <div className="flex px-5">
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Max Capacity"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          onChange={(e) => handlePropertyFieldChange(e, "max")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Number of Bedrooms"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          onChange={(e) =>
                            handlePropertyFieldChange(e, "bedrooms")
                          }
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextField
                          label="Number of Beds"
                          type="number"
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          onChange={(e) => handlePropertyFieldChange(e, "beds")}
                        />
                      </Grid>
                    </Grid>
                  </div> */}
                </TabPanel>
              )}
            </Box>
          </div>
          {/* Footer */}
          <div className="my-2 py-3 px-4 flex justify-end gap-2 -pt-2">
            <Button
              className="bottom-3"
              size="small"
              variant="outlined"
              color="info"
              onClick={handleModalClose}
              disabled={didSubmit}
            >
              Back
            </Button>

            {isAdmin && (
              <>
                <Button
                  className="bottom-3"
                  size="small"
                  variant="outlined"
                  color="success"
                  onClick={handleSubmit}
                  disabled={didSubmit}
                  startIcon={
                    didSubmit ? (
                      <CircularProgress size={12} color="inherit" />
                    ) : (
                      <></>
                    )
                  }
                >
                  Save
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export default AddProperty;
