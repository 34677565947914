import { Button } from "@mui/material";
import React from "react";

const SyncProperties = ({ setOpenSyncModal, handleModalSync }) => {

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 backdrop-blur-sm flex items-center justify-center z-50">
      <div className="bg-white w-11/12 md:w-1/2 lg:w-1/3 rounded-lg shadow-lg overflow-hidden">
        {/* Header */}
        <div className="bg-gray-100 py-2 px-4">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-extrabold tracking-tight text-slate-900">
              Sync Properties
            </h2>
            <button
              onClick={() => setOpenSyncModal(false)}
              className={`text-gray-500 `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Body */}
        <div className="p-4">
          <h4
            className="text-base"
            style={{
              textAlign: "left",
              color: "#555",
              display: "inline",
            }}
          >
            Reflecting data from Hospitable might take a while, are you sure you want to proceed?
          </h4>
        </div>

        {/* Footer */}
        <div className="py-3 px-4 flex justify-end gap-2">
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="info"
            onClick={() => setOpenSyncModal(false)}
          >
            Back
          </Button>
          <Button
            className="bottom-3"
            size="small"
            variant="outlined"
            color="success"
            onClick={handleModalSync}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SyncProperties;
